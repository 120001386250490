import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { FindOutMoreButton, Image, DesktopRightImage, PhoneImage, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="solutions/hafjell_bikepark.jpg" className="mt-12 mb-4 mx-auto" mdxType="Image" />
    <p className="text-xs mb-16 text-center">Photo: Gisle Johnsen</p>
    <p className="justify-text leading-relaxed text-xl">
  <a href="https://www.hafjell.no/sommer">Hafjell</a> is Norway's leading
  destination for lift-accessed biking. The bike park has 19 trails of various
  levels of difficulty, with a total length of 25 km. Outside of the bike park,
  the area offers fantastic opportunities for all other types of cycling and
  hiking.
    </p>
    <p className="px-12 pt-6 bg-gray-100 text-8xl font-bold">“</p>
    <p className="px-12 bg-gray-100 justify-text italic leading-relaxed text-xl">
  We use Trailguide to be able to collect all our different activities on one
  platform ... We use a lot of QR codes that we print on signs for walking and
  cycling ... We also use QR codes on all our rental bikes as links to a map
  with tour suggestions that fit the bike you have rented. In the future, I hope
  that we can extend the usage of Trailguide in the region and get our trail
  network connected to Lillehammer and further north in Gudbrandsdalen. This
  will make it easier for cyclists planning long trips and for those who want to
  stay in the region for several days.
  <br />
  <br />
  Daniel Eriksson, Manager Hafjell Bike Park
    </p>
    <p className="px-12 bg-gray-100 text-8xl font-bold text-right">“</p>
    <p className="mb-16"></p>
    <h4>{`A digital information system`}</h4>
    <DesktopRightImage className="-mt-8 mb-12" src="solutions/laptop_phone_hafjell_warm.jpg" mdxType="DesktopRightImage" />
    <PhoneImage src="solutions/laptop_phone_hafjell_warm.jpg" mdxType="PhoneImage" />
    <p className="justify-text leading-relaxed text-xl">
  Hafjell wanted digital maps to show website visitors what the destination
  offers, and help them to plan their trip. With the Trailguide web-app visitors
  are guided on the trails, both in and outside the park.
    </p>
    <Image src="solutions/hafjell_family.jpg" className="mb-4 sm:mt-24 mx-auto" mdxType="Image" />
    <p className="text-xs mb-16 text-center">Photo: Gisle Johnsen</p>
    <h4>{`QR-codes connect to the digital world`}</h4>
    <div className="justify-text leading-relaxed text-xl">
  QR-codes and Trailguides easy access technology make it easy for guests to
  instantly get to the right information. Hafjell has printed QR-codes on signs,
  infoboards, and stickers on rental bikes.
      <Image src="solutions/hafjell_rentals.jpg" className="mt-12 mb-4 mx-auto" mdxType="Image" />
      <p className="text-xs mb-16 text-center">Photo: Gisle Johnsen</p>
      <p>{`In the Trailguide management portal
the destination created specific activity collections. Each collection has its
own URL and QR-code. Scanning the code opens Trailguide with the right
information and the functionality of the app. No download, no installation, no
registration is required.`}</p>
    </div>
    <TrailguidePlugin content="/x46" search="false" theme="light" mdxType="TrailguidePlugin" />
    <p className="text-xs -mt-8 mb-12 text-center">
  Interactive map of mountain bike tours outside the bike park
    </p>
    <div className="flex justify-center mt-12">
  <FindOutMoreButton to="/solutions/resort" className="text-xl" mdxType="FindOutMoreButton">
    Find out more about our resort solutions
  </FindOutMoreButton>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      